import React from 'react'
// import "./home.css"

import img1 from "../assets/1.webp"

import img2 from "../assets/2.webp"


export default function Mobile() {
  return (
    <>
<body class="flex bg-gray-100 font-sans items-center" style={{minHeight: '100vh'}}>
    <div>

    <a href="https://178.128.83.165/register?ref=adsg1" target='_blank'>
<img className='object-fill h-48 w-full p-2' src={img1} alt="" /></a>

<a  href="https://178.128.83.165/register?ref=adsg1" target='_blank'>
<img className='object-fill w-full h-48 p-2' src={img1} alt="" /></a>
</div>

</body>
    </>
  )
}
