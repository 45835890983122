
import './App.css';
import Home from './pages/home';
import useWindowDimensions from '../src/utils/getDimension.ts';
import Mobile from './pages/mobile';


function App() {
  const { height, width } = useWindowDimensions();
  return (
    <div className="App">
   {width <= 500 ? ( <Mobile/> ) : ( <Home/> )}
    </div>
  );
}

export default App;
