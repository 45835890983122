import React from 'react'

import ImgX from "../assets/Fashion3.webp"
import ThisShirt from "../assets/shirt.jpeg"

export default function Home() {
  return (
    <div>
    <body class="bg-gray-100 font-sans">


<nav class="bg-white shadow-md">
    <div class="container mx-auto px-4 py-2">
        <div class="flex justify-between items-center">
            <div class="text-2xl font-semibold text-gray-800">Clothart</div>
            <ul class="flex space-x-4">
                <li><a href="#" class="text-gray-600 hover:text-gray-800">Home</a></li>
                <li><a href="#" class="text-gray-600 hover:text-gray-800">Collections</a></li>
                <li><a href="#" class="text-gray-600 hover:text-gray-800">About Us</a></li>
                <li><a href="#" class="text-gray-600 hover:text-gray-800">Contact</a></li>
            </ul>
        </div>
    </div>
</nav>


<header class="bg-cover bg-center h-96 relative" style={{ backgroundImage: `url(${ImgX})` }}>
    <div class="absolute inset-0 bg-black opacity-50"></div>
    <div class="container mx-auto h-full flex items-center w-full justify-center">
        <div class="text-center text-white z-10">
            <h1 class="text-4xl font-extrabold leading-tight mb-4">Discover Your Style with Clothart</h1>
            <p class="text-lg mb-6">Explore our latest fashion collections</p>
            <a href="#" class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full inline-block">Shop Now</a>
        </div>
    </div>
</header>


<section class="py-12">
    <div class="container mx-auto px-4">
        <h2 class="text-3xl font-semibold mb-6">Featured Products</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
         
            <div class="bg-white shadow-md rounded-lg overflow-hidden">
                <img src={{ThisShirt}}   class="w-full h-48 object-cover"/>
                <div class="p-4">
                    <h3 class="text-xl font-semibold mb-2">Aurora Elegance</h3>
                    <p class="text-gray-600">Aurora Elegance is a high-end fashion brand known for its luxurious eveningwear. Their collections feature exquisite gowns and dresses adorned with intricate beadwork and embroidery. With a focus on timeless elegance, Aurora Elegance brings sophistication to every special occasion.</p>
                    <div class="mt-4 flex justify-between items-center">
                        <span class="text-xl font-bold text-gray-800">$49.99</span>
                        <a href="#" class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full inline-block">Add to Cart</a>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow-md rounded-lg overflow-hidden">
                <img src={{ThisShirt}}   class="w-full h-48 object-cover"/>
                <div class="p-4">
                    <h3 class="text-xl font-semibold mb-2">Urban Vanguard</h3>
                    <p class="text-gray-600">Urban Vanguard is a contemporary streetwear brand that caters to the fashion-forward youth. Their edgy designs incorporate bold prints, oversized silhouettes, and vibrant colors, reflecting the spirit of urban culture. From graphic tees to statement sneakers, Urban Vanguard sets trends in street fashion.</p>
                    <div class="mt-4 flex justify-between items-center">
                        <span class="text-xl font-bold text-gray-800">$49.99</span>
                        <a href="#" class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full inline-block">Add to Cart</a>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow-md rounded-lg overflow-hidden">
                <img src={{ThisShirt}}   class="w-full h-48 object-cover"/>
                <div class="p-4">
                    <h3 class="text-xl font-semibold mb-2">Woodland Nomad</h3>
                    <p class="text-gray-600">Velvet Aura specializes in chic and sophisticated women's wear. Their collections feature rich, velvety fabrics and timeless designs. From classic tailored blazers to elegant evening dresses, Velvet Aura adds a touch of luxury to every wardrobe, making women feel confident and refined.</p>
                    <div class="mt-4 flex justify-between items-center">
                        <span class="text-xl font-bold text-gray-800">$49.99</span>
                        <a href="#" class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full inline-block">Add to Cart</a>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow-md rounded-lg overflow-hidden">
                <img src={{ThisShirt}}   class="w-full h-48 object-cover"/>
                <div class="p-4">
                    <h3 class="text-xl font-semibold mb-2">Retro Revival</h3>
                    <p class="text-gray-600">Retro Revival takes a nostalgic trip back in time with its vintage-inspired clothing. From 1950s swing dresses to '80s graphic tees, this brand celebrates iconic fashion eras. With meticulous attention to detail, Retro Revival captures the essence of retro style for modern fashion enthusiasts.</p>
                    <div class="mt-4 flex justify-between items-center">
                        <span class="text-xl font-bold text-gray-800">$49.99</span>
                        <a href="#" class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full inline-block">Add to Cart</a>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow-md rounded-lg overflow-hidden">
                <img src={{ThisShirt}}   class="w-full h-48 object-cover"/>
                <div class="p-4">
                    <h3 class="text-xl font-semibold mb-2">Active Fusion

</h3>
                    <p class="text-gray-600">Active Fusion is a sportswear brand that seamlessly combines performance and style. Their athleisure collections feature moisture-wicking fabrics, ergonomic designs, and bold patterns. Whether hitting the gym or running errands, Active Fusion ensures comfort and fashion go hand in hand.</p>
                    <div class="mt-4 flex justify-between items-center">
                        <span class="text-xl font-bold text-gray-800">$49.99</span>
                        <a href="#" class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full inline-block">Add to Cart</a>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow-md rounded-lg overflow-hidden">
                <img src={{ThisShirt}}   class="w-full h-48 object-cover"/>
                <div class="p-4">
                    <h3 class="text-xl font-semibold mb-2">Sapphire Skies</h3>
                    <p class="text-gray-600">Sapphire Skies is a beachwear and swimwear brand inspired by tropical paradise. Their designs evoke the essence of sandy beaches and crystal-clear waters. With bikinis, cover-ups, and sun hats in vibrant colors and floral prints, Sapphire Skies helps you embrace the sunshine in style.</p>
                    <div class="mt-4 flex justify-between items-center">
                        <span class="text-xl font-bold text-gray-800">$49.99</span>
                        <a href="#" class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full inline-block">Add to Cart</a>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow-md rounded-lg overflow-hidden">
                <img src={{ThisShirt}}   class="w-full h-48 object-cover"/>
                <div class="p-4">
                    <h3 class="text-xl font-semibold mb-2">Gentleman's Edge

</h3>
                    <p class="text-gray-600">Gentleman's Edge redefines men's fashion with a blend of traditional craftsmanship and contemporary flair. Their suits, shirts, and accessories are tailored to perfection, offering a modern twist on classic menswear. With attention to detail and premium fabrics, Gentleman's Edge suits the modern man who values both style and tradition.</p>
                    <div class="mt-4 flex justify-between items-center">
                        <span class="text-xl font-bold text-gray-800">$49.99</span>
                        <a href="#" class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full inline-block">Add to Cart</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<footer class="bg-gray-800 text-white py-8">
    <div class="container mx-auto text-center">
        <p>&copy; 2023 Clothart. All rights reserved.</p>
    </div>
</footer>

</body>
    </div>
  )
}
